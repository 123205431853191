.App {
  font-family: 'AkiraExpanded';
  text-align: center;
  background-color: black;
  min-height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.App h1 {
  margin: 0.1em 0;
  color: white;
  font-weight: normal;
  font-family: 'AkiraExpanded';
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

@media (max-width: 1440px) {
  .App h1 {
    font-size: 2.0em;
  }
}
@media (max-width: 1280px) {
  .App h1 {
    font-size: 1.7em;
  }
}
@media (max-width: 900px) {
  .App h1 {
    font-size: 1.3em;
  }
}

.App h3 {
  margin: 0.1em 0;
  color: white;
  font-weight: bold;
}

.PagePaddingTop {
  padding-top: 12.0em;
}

@media (max-width: 1440px) {
  .App h3 {
    font-size: 1.4em;
  }
  .PagePaddingTop {
    padding-top: 10.0em;
  }
}
@media (max-width: 1280px) {
  .App h3 {
    font-size: 1.2em;
  }
  .PagePaddingTop {
    padding-top: 9.0em;
  }
}
@media (max-width: 900px) {
  .App h3 {
    font-size: 1.0em;
  }
  .PagePaddingTop {
    padding-top: 7.0em;
  }
}

.FilterAdditionalBrightness {
  filter: brightness(1.2);
}