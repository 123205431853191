.MenuM {
  z-index: 5;
  position: absolute; /* Reposition logo from the natural layout */
  width: 100%;
  color: white;

  font-size: 2.0em;
  text-align: left;
}
/* x times of MunuM*/
.MenuM .title {
  font-size: 1.3em;
}
.MenuM .navbar-brand{
  font-size: 2.0em;
}

@media (max-width: 1280px) {
  .MenuM{
    font-size: 1.7em;
  }
  /* x times of MunuM*/
  .MenuM .title{
    font-size: 1.3em; 
  }
  .MenuM .navbar-brand{
    font-size: 1.7em;
  }
}
@media (max-width: 900px) {
  .MenuM{
    font-size: 1.3em;
  }
  /* x times of MunuM*/
  .MenuM .title {
    font-size: 1.3em;
  }
.MenuM .navbar-brand{
    font-size: 1.3em;
  }
}

.Menu {
  position: absolute; /* Reposition logo from the natural layout */
  left: 25px;
  top: 0px;
  z-index: 5;
  color: white;
  
  text-align: left;
}

.Menu-item {
  color: white;
  text-decoration: none;
}

.Menu-item-selected {
  color: white;
  text-decoration: underline;
}

.Menu-sub-item {
  color: white;
  text-decoration: none;
}

.Menu-dropdown-item {
  color: white;
  text-decoration: none;
}


.MenuM a {
  color: white !important;
  text-decoration: none !important;
  font-weight: bold;
  text-shadow: 0 0 2px #000;
  -moz-text-shadow: 0 0 2px #000;
  -webkit-text-shadow: 0 0 2px #000;
}

.navbar{
  color: white;
  font-weight: bold;
  padding-top: 0px !important;
}
.nav-link{
  padding-top: 0px !important;
}

@media (max-width: 767px) {
  .navbar {
    background-color: black;
  }
}

@media (max-width: 767px) {
  .navbar-collapse {
    height: 100vh;
    overflow-y: scroll !important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .navbar-collapse::-webkit-scrollbar {
    display: none;
  }
}

.dropdown-film .dropdown-menu {
  height: 50vh;
  max-height: 280px; /* 1item: 35, TODO: find way to override. .dropdown-menu is hidden class? */
  background-color: black !important;
  overflow-y: scroll !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.dropdown-film .dropdown-menu::-webkit-scrollbar {
  display: none;
}
@media (max-width: 767px) {
  .dropdown-film .dropdown-menu {
    height: 100%;
  }
}

.dropdown-photo .dropdown-menu {
  background-color: black !important;
}

.dropdown-item {
  color: white !important;
  background-color: black !important;
}
.dropdown-item:hover {
  color: black !important;
  background-color: white !important;
}

/* Remove arrow next to NavDropdown */
.dropdown-toggle::after {
  display: none !important; 
}