.TextList {
  overflow-y: scroll;
}

.TextList::-webkit-scrollbar {
  display: none;
}

.TextList {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}